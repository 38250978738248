<template>
    <div style="background: #404040">
        <div>
            <headerComponent></headerComponent>
        </div>
        <div class="cookies">
            <p>MONOVERSE PRIVACY POLICY</p>
            <p>&nbsp;</p>
            <p>1. Introduction</p>
            <p>&nbsp;</p>
            <p>Monoverse.com (hereafter the &ldquo;Company&rdquo; or the &ldquo;Website&rdquo;) takes your privacy, and the ones of other individuals we interact with for commercial purposes, very seriously. The&nbsp; collecting and use of your personal information are done for the strict purpose of our&nbsp;products conduct and to provide you with reliable services. This Privacy Policy explains what kinds of personal data we may collect about you. Personal data means any&nbsp;information relating to an identified or identifiable natural person, such as:</p>
            <p>o Name and email address as requested by contact forms.</p>
            <p>o Billing and payment information upon checkout</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>It explains how and why it may be shared, how we keep it safe, but it also states your rights to review it and how to modify or take control of this information. This data is&nbsp;collected directly from you when you complete forms on our website or get in touch with us by any means, including our use of cookies, server logs, and similar technologies.</p>
            <p>More detail on this is available in our Cookie Policy &ndash; <a href="http://monoverse.com/cookies" data-fr-linked="true">http://monoverse.com/cookies</a>-policy.</p>
            <p>Monoverse is committed to protecting and respecting your privacy and personal data. If&nbsp;you have any questions, please get in touch with.</p>
            <p>&nbsp;</p>
            <p>2. Who we are</p>
            <p>Monoverse Platform Inc., the owner of the platform and technology, is a Delawarean company that complies with the California Consumer Privacy Act, the California Online&nbsp;Privacy Protection Act, the General Data Protection Regulation (GDPR) and Children&rsquo;s&nbsp;Online Privacy Protection Act (COPPA). Our registered address is 651 N Broad Street, Suite 201, Middletown Delaware, 19709.&nbsp;Monoverse Capital Ltd, the owner of the domains, is a UK company that complies with</p>
            <p>The Consumer Rights Act 2015, The Data Protection Act 2018, and The Money Laundering, Terrorist Financing and Transfer of Funds Regulations of United Kingdom.&nbsp;Our registered address is Level 1, 124 Cleveland Street, London, W1T 6PH.&nbsp;</p>
            <p>&nbsp;</p>
            <p>3. What information Monoverse.com collects and how:</p>
            <p>Monoverse collects customers&amp;#39; relevant information, including your name email address and other data used to directly or indirectly to identify you in connection to your use of&nbsp;services. Our service may require our customers to provide Monoverse with additional&nbsp;information such as credit card information, associated billing information, or other financial and payment information. In order to protect our customers and comply with AML regulations, Monoverse may require you to provide government identification&nbsp;and/or other documentation as proof of identity and physical address for the purchase of&nbsp;domains where value exceeds the amount of $ 10,000.</p>
            <p>We collect personal data in the following circumstances:</p>
            <p>1. When you visit our website</p>
            <p>You can visit our Website and learn about our activities without having to provide us with any personal information. However, Monoverse, obtains and stores data either in its&nbsp;internal databases or by the using third party apps and specifically Google Analytics. This information may include but is not limited to: Internet protocol (IP) addresses,&nbsp;browser type, Internet service provider (ISP), referring entry/exit pages, operating system, date/time stamp and/or clickstream data, and other data that could directly or&nbsp;indirectly identify you.&nbsp;We use this information for our internal analytics purposes and to improve the quality&nbsp;and relevance of our Website to our visitors. Indeed, we use that information to ensure&nbsp; that content from our Website is presented in the most effective manner for you and for our computer. Some of this information may be collected using cookies and similar tracking technology, as explained further in our Cookie Policy &ndash;&nbsp;<a href="http://monoverse.com/cookies-policy" data-fr-linked="true">http://monoverse.com/cookies-policy</a>. This data may be processed by third party service providers and processors we use in connection with the services they provide to us, including to support us in areas such as IT platform management or support services, infrastructure and application services, marketing and data analytics.</p>
            <p>If you are in a jurisdiction that requires opt-in consent before placement of non-essential cookies and tracking technologies in your browser, we will request your consent before&nbsp;such cookies are placed in your browser through our sites. In addition, if you have&nbsp;configured your browser to send a &ldquo;Do Not Track&rdquo; or Global Privacy Control signal, we will honor that signal as your automated request to opt-out of non-essential cookies and&nbsp;tracking technologies.</p>
            <p>&nbsp;</p>
            <p>2. When you subscribe to receive supporting information from Monoverse&nbsp;</p>
            <p>Essentially, the type of data collected is limited to your name, your address and the descriptive text of your request. The purpose of the processing is to inform you through&nbsp;Customer Support, about Monoverse services and goods and its activities in general.&nbsp;Regarding the Legal basis for the UK and EEA, Monoverse legitimate interest justifies that we may send information about its upcoming updates or news to its existing clients&nbsp;or to people who previously requested our support. For non-customers, the processing&nbsp;of your personal data is based on your ongoing consent. To opt-out the list of correspondents, you are kindly requested to reach out via our contact form.&nbsp;</p>
            <p>3. When you discover Monoverse or purchase a Monoverse Domain</p>
            <p>Monoverse.com might process your contact details (name, address, email address and telephone number) and invoicing data. Additionally, as part of its services, we might collect and process basic personal data. We process your data to provide you with the service that you ordered from us and communicate with you about it.&nbsp;When someone inquires about a domain name that is registered at Monoverse, that&nbsp;person may be addressing the owner, or Monoverse, or (due to confusion) someone else. Such communications pass through Monoverse&amp;#39;s system, and we keep a record:</p>
            <p>o Name</p>
            <p>o Email Address</p>
            <p>o Enquiry Text</p>
            <p>4. Why Monoverse collects personal data</p>
            <p>We use our customers information for our internal analytics purposes and to improve the quality and relevance of our Website to our visitors. Indeed, we use that information to&nbsp;ensure that content from our Website is presented in the most effective manner for you and&nbsp;for your computer. Some of this information may be collected using cookies and similar tracking technology, as explained further in our Cookie Policy &ndash;&nbsp;<a href="http://monoverse.com/cookies-policy" data-fr-linked="true">http://monoverse.com/cookies-policy</a>. This data may be processed by third party service providers and processors we use in&nbsp;connection with the services they provide to us, including to support us in areas such as IT&nbsp;platform management or support services, infrastructure and application services, marketing&nbsp;and data analytics.</p>
            <p>If you are in a jurisdiction that requires opt-in consent before placement of non-essential cookies and tracking technologies in your browser, we will request your consent before such&nbsp;&nbsp;cookies are placed in your browser through our sites. In addition, if you have configured your browser to send a &ldquo;Do Not Track&rdquo; privacy control signal, we will honor that signal as&nbsp;your automated request to opt-out of non-essential cookies and tracking technologies. Moreover, we may use your Personal Data for the following purposes:&nbsp;</p>
            <p>- To provide and maintain our products and services, including to monitor the usage of our products and services.</p>
            <p>- To manage your account: to manage your registration as a user of our services. The&nbsp;Personal Data you provide can give you access to different functionalities of the services that are available to you as a registered user.</p>
            <p>- For the performance of your contract: the development, compliance and undertaking&nbsp;of the purchase contract when you purchase a domain, items or services you have purchased or of any other contract with us through the service.</p>
            <p>- To contact you: to contact you by email, telephone calls, SMS, or other equivalent&nbsp;forms of electronic communication, such as a mobile application&amp;#39;s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
            <p>- To provide you with news and general information about other products and service&nbsp;which we offer that are similar to those that you have already purchased or enquired&nbsp;about unless you have opted not to receive such information.</p>
            <p>- To manage your domain transfer requests: to attend and manage your requests to&nbsp;transfer the ownership of a domain name.</p>
            <p>- For other purposes: we may use your information for other purposes, such as data&nbsp;analysis, identifying usage trends, determining the effectiveness of our promotional&nbsp;campaigns and to evaluate and improve our services, products, marketing and your experience.</p>
            <p>&nbsp;</p>
            <p>We may share your personal information in the following situations:</p>
            <p>- With service providers: we may share your personal information with Service Providers to monitor and analyze the use of our services, to contact you.&nbsp;</p>
            <p>- For business transfers: We may share or transfer your personal information in connection with, or during negotiations of, any sale of Domain Names, financing the&nbsp;acquisition of a Domain Name, or a portion of our business to another company.</p>
            <p>&nbsp;</p>
            <p>- With Affiliates: we may share your information with our affiliates, in which case we will&nbsp;require those affiliates to honor this Privacy Policy. Affiliates include our parent company&nbsp;and any other subsidiaries, joint venture partners or other companies that we control or&nbsp; that are under common control with us.</p>
            <p>- With business partners: we may share your information with our business partners to&nbsp;offer you certain products, services, or promotions.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>5. Tracking Technologies and Cookies we use</p>
            <p>We use Cookies and similar tracking technologies as provided by Google and Cloudflare to&nbsp;track the activity on our service and store certain information. Tracking technologies used are&nbsp;Beacons, Tags, and Scripts, explicitly utilized by common internet standards, to collect and&nbsp;track information and to improve and analyze our Service. The technologies we use may include:</p>
            <p>- Cookies or Browser Cookies. A Cookie is a small file placed on your device. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our service. Unless you have adjusted your browser setting so that it will refuse Cookies, our service may use Cookies.</p>
            <p>&nbsp;</p>
            <p>- Web Beacons. Certain sections of our Service and our emails may contain small&nbsp;electronic files known as web Beacons (also referred to as clear gifs, pixel tags, and&nbsp;single-pixel gifs) that permit Monoverse, for example, to count users who have visited&nbsp;those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity). Cookies can be &amp;quot;Persistent&amp;quot; or &amp;quot;Session&amp;quot; Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while session Cookies are deleted as soon as you close your web browser.</p>
            <p>We use both session and persistent Cookies for the purposes set out below:</p>
            <p>&nbsp;</p>
            <p>- Necessary / Essential Cookies&nbsp;</p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>Purpose: These cookies are essential to provide you with services available through the Monoverse website and platform, and to enable you to use some of its features. They&nbsp; help to authenticate users and prevent fraudulent use of user accounts. Without these&nbsp; Cookies, the services that you have asked for cannot be provided, and we only use &nbsp;these Cookies to provide you with those services.</p>
            <p>- Cookies Policy / Notice Acceptance Cookies</p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>Purpose: these Cookies identify if users have accepted the use of cookies on the Monoverse website and platform.</p>
            <p>- Functionality Cookies</p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>Purpose: these Cookies allow us to remember choices you make when you use the&nbsp;website and the platform such as remembering your login details or language&nbsp;preference. The purpose of these Cookies is to provide you with a more personal&nbsp;experience and to avoid You having to re-enter your preferences every time you use the&nbsp;website.</p>
            <p>&nbsp;</p>
            <p>For more information about the Cookies we use and your choices regarding Cookies, please&nbsp;visit our Cookie Policy &ndash; <a href="http://monoverse.com/cookies-policy" data-fr-linked="true">http://monoverse.com/cookies-policy</a>.</p>
            <p>&nbsp;</p>
            <p>6. How do we retain your data</p>
            <p>&nbsp;</p>
            <p>Monoverse will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal data to the extent necessary to&nbsp;comply with our legal obligations (for example, if we are required to retain your data to comply&nbsp;with applicable AML laws on the acquisition of domains), resolve disputes, and enforce our legal&nbsp;agreements and policies. Monoverse will also retain usage data for internal analysis purposes. Usage data is generally&nbsp;retained for a shorter period of time, except when this data is used to strengthen the security or&nbsp;to improve the functionality of our Service, or we are legally obligated to retain this data for&nbsp;longer time periods.</p>
            <p>&nbsp;</p>
            <p>7. With who do we share your data with</p>
            <p>We may share your personal information with affiliated companies within our corporate family,&nbsp;with third parties which we have partnered to allow you to integrate their services into our own&nbsp;Services, and with trusted third party service providers as necessary for them to perform&nbsp;services on our behalf, such as:</p>
            <p>- Stripe and Paypal for processing credit card payments</p>
            <p>- Serving advertisements (more on this topic below)</p>
            <p>- Performing analysis of our Services and customers demographics</p>
            <p>- Communicating with you by phone and email</p>
            <p>- Customer relationship management</p>
            <p>- Security, risk management and compliance</p>
            <p>- Recruiting support and related services</p>
            <p>&nbsp;</p>
            <p>The third parties (and any subcontractors they may be permitted to use) have agreed not to share, use or retain your personal information for any purpose other than as necessary for&nbsp; the provision of Services. We will also disclose your information to third parties (and any subcontractors they may be&nbsp;permitted to use) have agreed not to share, use or retain your personal information for any&nbsp;purpose other than as necessary for the provision of Services.</p>
            <p>&nbsp;</p>
            <p>We will also disclose your information to third parties:</p>
            <p>- in the event that we sell or buy any business or assets (whether a result of liquidation,&nbsp;bankruptcy or otherwise), in which case we will disclose your data to the prospective&nbsp;seller or buyer of such business or assets; or</p>
            <p>- if we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or all of our assets. In such transactions, your information may be among the&nbsp;&nbsp;transferred assets.</p>
            <p>&nbsp;</p>
            <p>8. In what conditions do we transfer your information</p>
            <p>&nbsp;</p>
            <p>Your information, including Personal Data, is processed at the Monoverse&amp;#39;s operating offices in the UK and in any other places where the parties involved in the processing are located. It&nbsp;means that this information may be transferred to &mdash; and maintained on &mdash; computers&nbsp;located outside of your state, province, country, or other governmental jurisdiction where the&nbsp;data protection laws may differ than those from your jurisdiction.</p>
            <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer, irrespective of the geographic location of our business. The Company will take all steps reasonably necessary to ensure that your data is treated&nbsp;securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place&nbsp;including the security of your data and other personal information.</p>
            <p>&nbsp;</p>
            <p>9. When do we delete your information</p>
            <p>&nbsp;</p>
            <p>You have the right to delete or request that we assist in deleting the Personal Data that we&nbsp; have collected about you. Our Service may give you the ability to delete certain information&nbsp;about you from within the Service. You may update, amend, or delete your information at any time by contacting your sales&nbsp;representative. You may also contact us to request access to, correct, or delete any&nbsp;personal information that you have provided to us.</p>
            <p>&nbsp;</p>
            <p>Please note, however, that we may need to retain certain information when we have a legal&nbsp;obligation or lawful basis to do so.</p>
            <p>&nbsp;</p>
            <p>10. Disclosure of your Personal Data</p>
            <p>Business transactions</p>
            <p>If Monoverse is involved in a merger, acquisition or asset sale, your Personal Data may be&nbsp;transferred. We will provide notice before your Personal Data is transferred and becomes&nbsp;subject to a different Privacy Policy.</p>
            <p>&nbsp;</p>
            <p>11. Law enforcement</p>
            <p>Under certain circumstances, Monoverse may be required to disclose your Personal Data if&nbsp;required to do so by law or in response to valid requests by public authorities (e.g. a court or&nbsp;a government agency).</p>
            <p>&nbsp;</p>
            <p>12. Other legal requirements</p>
            <p>Monoverse may disclose Your Personal Data in the good faith belief that such action is&nbsp;necessary to:&nbsp;</p>
            <p>- Comply with a legal obligation.</p>
            <p>- Protect and defend the rights or property of Monoverse Platform Inc.</p>
            <p>- Prevent or investigate possible wrongdoing in connection with the Service.</p>
            <p>- Protect the personal safety of Users of the Service or the public.</p>
            <p>- Protect against legal liability.</p>
            <p>&nbsp;</p>
            <p>13. Security of your Personal Data</p>
            <p>The security of your Personal Data is important to us, but remember that no method of transmission over the internet, or method of electronic storage is 100% secure. While we&nbsp;strive to use commercially acceptable means to protect your Personal Data, we cannot&nbsp; guarantee its absolute security.</p>
            <p>At any time, you can request to know what personal information related to you &ndash; if&nbsp;any &ndash; Monoverse has stored or processed.&nbsp;All customers can modify their preferences regarding email notifications. Monoverse&nbsp;gives you granular control over which emails you receive &ndash; and how frequently we&nbsp; send them. Settings can be found and modified within your Monoverse account at&nbsp;any time. Though, of course, we do include an &amp;#39;unsubscribe&amp;#39; link in the email itself,we recommend modifying your permissions within the account. Clicking&nbsp;&amp;#39;unsubscribe&amp;#39; can cause other kinds of email notifications to be blocked &ndash; not just&nbsp;those that resemble the message in question.&nbsp;If you are an EU citizen, then beginning May 25, 2018, you have detailed privacy&nbsp;rights governed by the European Union&amp;#39;s General Data Protection Regulation&nbsp; (GDPR). Specifically, you have the right to:</p>
            <p>&nbsp;</p>
            <p>1. Access (GDPR 15)</p>
            <p>2. Rectification (GDRP 16)</p>
            <p>3. Erasure (GDPR 17)</p>
            <p>4. Restriction (GDPR 18)</p>
            <p>5. Portability (GDPR 20)</p>
            <p>6. Objection (GDPR 21)</p>
            <p>For more information, consult the GDPR regulation itself, referring to the articlenumbers cited above. If you believe Monoverse is not complying with the GDPR in some respect, please let us know. We will make every effort to abide by this new&nbsp;law.</p>
            <p>&nbsp;</p>
            <p>14. Children&rsquo;s Privacy</p>
            <p>Our Service does not address anyone under the age of 18. We do not knowingly collect&nbsp;personally identifiable information from anyone under the age of 18. If You are a parent or&nbsp; guardian and you are aware that your child has provided us with Personal Data, please&nbsp;contact us. If we become aware that we have collected Personal Data from anyone under&nbsp;the age of 18 without verification of parental consent, we take steps to remove that&nbsp;information from our servers. If we need to rely on consent as a legal basis for processing your information and your&nbsp;country requires consent from a parent, we may require your parent&amp;#39;s consent before we&nbsp;collect and use that information.</p>
            <p>&nbsp;</p>
            <p>15. Links to other websites</p>
            <p>Our Service may contain links to other websites that are not operated by us such as Stripe.&nbsp;If you click on a third party link, you will be directed to that third party&amp;#39;s site. We strongly&nbsp;advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.&nbsp;</p>
            <p>16. Changes to this Privacy Policy</p>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by&nbsp;posting the new Privacy Policy on this page.&nbsp;We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &amp;quot;Last updated&amp;quot; date at the top of this Privacy Policy.&nbsp;You are advised to review this Privacy Policy periodically for any changes. Changes to this&nbsp;Privacy Policy are effective when they are posted on this page.</p>
            <p>17. Contact Us</p>
            <p>If you have any questions about this Privacy Policy, you can contact us byy visiting this&nbsp;page on our website:<a href="http://www.monoverse.com/contact" data-fr-linked="true"> http://www.monoverse.com/contact</a></p>
        </div>

        <div>
            <mainfooter></mainfooter>
        </div>
    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import nextStepsG from "@/components/reusable/nextSteps";

    export default {
        name: "thankYouMail",
        components: {headerComponent,mainfooter,DomainsGrid,nextStepsG},
        data: () => ({
            show:false,
        }),
        mounted() {
            this.show = true; // might need this.$nextTick
        },

    }
</script>

<style>


</style>


